import React, { useState } from 'react';
import { Tooltip } from 'antd';
import classNames from 'classnames';

import { Modal } from 'components';
import Icon from 'components/layout/Icon';

import ProductReplacementsForm from '../ProductReplacementsForm';

import styles from './ProductReplacementsButton.module.scss';

const colors = {
  gray: 'rgba(81, 92, 106, 0.6)',
  green: 'rgba(19, 101, 91, 0.75)'
};

const ProductReplacementsButton = ({ product, isCatalogView }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { group_product_price_flag, group_products_with_offers_ids } = product;

  if (group_products_with_offers_ids.length === 0 || group_product_price_flag === 0) {
    return null;
  }

  return (
    <>
      <Tooltip
        placement='top'
        title={'Dostępne zamienniki'}
      >
        <button
          onClick={() => setIsOpen(true)}
          className={classNames(styles.container, { [styles.green]: group_product_price_flag === 1 })}
        >
          <Icon
            name='replacement'
            size='18'
            fill={group_product_price_flag === 1 ? colors.green : colors.gray}
          />
        </button>
      </Tooltip>
      <Modal
        visible={isOpen}
        onClose={() => setIsOpen(false)}
        title={'Zamienniki'}
      >
        <ProductReplacementsForm
          productsWithOffersIds={group_products_with_offers_ids}
          currentProduct={product}
          closeModal={() => setIsOpen(false)}
          isCatalogView={isCatalogView}
        />
      </Modal>
    </>
  );
};

export default ProductReplacementsButton;
