import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import dayjs from 'dayjs';

import { AsyncSelect, Button, useIsAdmin, useValidator } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { OfferCatalogApi, UserApi } from 'src/api';
import { EXCEL_FILE_TYPE } from 'src/constants/misc';
import { downloadFile, getDecodeBase64File } from 'src/utils/helpers';

import style from '../../FlatFileHandling.module.scss';

const FlatFileExport = ({ selectedCompany }) => {
  const { listUID } = useParams();
  const validator = useValidator();
  const isAdmin = useIsAdmin();
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [isLoadingCSV, setIsLoadingCSV] = useState(false);
  const [isLoadingXLSX, setIsLoadingXLSX] = useState(false);
  const [isLoadingCSVAll, setIsLoadingCSVAll] = useState(false);
  const [isLoadingXLSXAll, setIsLoadingXLSXAll] = useState(false);
  const [isLoadingCSVSupplier, setIsLoadingCSVSupplier] = useState(false);
  const [isLoadingXLSXSupplier, setIsLoadingXLSXSupplier] = useState(false);
  const [isLoadingWholeCatalog, setIsLoadingWholeCatalog] = useState(false);

  const setIsLoading = (value, type, withSupplier, all) => {
    if (withSupplier && type === 'XLSX') {
      setIsLoadingXLSXSupplier(value);
    } else if (withSupplier && type === 'CSV') {
      setIsLoadingCSVSupplier(value);
    } else if (all && type === 'XLSX') {
      setIsLoadingXLSXAll(value);
    } else if (all && type === 'CSV') {
      setIsLoadingCSVAll(value);
    } else if (!withSupplier && type === 'XLSX') {
      setIsLoadingXLSX(value);
    } else if (!withSupplier && type === 'CSV') {
      setIsLoadingCSV(value);
    }
  };

  const downloadHandler = async (type, withSupplier = false, downloadAll = false) => {
    if (!selectedSupplier && withSupplier) return validator.showMessages();

    const queryData = {
      file_format: type,
      ...(isAdmin && { company_id: selectedCompany }),
      ...(withSupplier && { supplier_id: selectedSupplier.value }),
      ...(listUID && { list_uid: listUID })
    };

    const filename = withSupplier
      ? `katalog_ofert_dostawcy_${selectedSupplier.label.replaceAll(' ', '_')}`
      : downloadAll
      ? 'wszystkie_produkty_flat_file'
      : 'katalog_ofert_eksport';

    const date = dayjs().format('DD.MM.YYYY, HH:mm');

    const downloadCSV = async () => {
      const callback = downloadAll ? OfferCatalogApi.exportAllProductsForCatalog : OfferCatalogApi.exportCatalogCSV;
      const { data } = await callback(queryData);
      const blob = new Blob([data], { type: 'text/csv' });
      downloadFile(blob, filename + '_' + date + '.csv');
    };
    const downloadXLSX = async () => {
      const callback = downloadAll ? OfferCatalogApi.exportAllProductsForCatalog : OfferCatalogApi.exportCatalogXLSX;
      const { data } = await callback(queryData);
      const blob = getDecodeBase64File(data, EXCEL_FILE_TYPE);
      downloadFile(blob, filename + '_' + date + '.xlsx');
    };

    const callback = type === 'CSV' ? downloadCSV : downloadXLSX;

    try {
      setIsLoading(true, type, withSupplier, downloadAll);
      await callback();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false, type, withSupplier, downloadAll);
      if (withSupplier) validator.hideMessages();
    }
  };

  const downloadWholeCatalog = async () => {
    const filename = 'eksport_wszystkich_ofert';
    const date = dayjs().format('DD.MM.YYYY, HH:mm');
    const queryData = {
      file_format: 'XLSX',
      ...(isAdmin && { company_id: selectedCompany })
    };

    try {
      setIsLoadingWholeCatalog(true);
      const { data } = await OfferCatalogApi.exportCatalogSuppliersOffers(queryData);
      const blob = getDecodeBase64File(data, EXCEL_FILE_TYPE);
      downloadFile(blob, filename + '_' + date + '.xlsx');
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoadingWholeCatalog(false);
    }
  };

  return (
    <div className={style.container}>
      <header className={style.header}>
        <h3>Export szablonu</h3>
        <p>
          Pobierz szablon z Twoimi produktami, aby wypełnić go cenami dostawcy. Plik możesz przesłać Twojemu dostawcy, aby
          przygotował w nim wycenę. Następnie przejdź do zakładki <strong>Import</strong>, i prześlij wpisane dane.
        </p>
      </header>
      <div className={style.buttonsWrapper}>
        <Button
          label={'Pobierz szablon (.csv)'}
          isLoading={isLoadingCSV}
          onClick={() => downloadHandler('CSV')}
        />
        <Button
          label={'Pobierz szablon Excel (.xlsx)'}
          isLoading={isLoadingXLSX}
          onClick={() => downloadHandler('XLSX')}
        />
        {isAdmin && (
          <>
            <Button
              label={'Wszystkie produkty (.csv)'}
              isLoading={isLoadingCSVAll}
              onClick={() => downloadHandler('CSV', false, true)}
            />
            <Button
              label={'Wszystkie produkty (.xlsx)'}
              isLoading={isLoadingXLSXAll}
              onClick={() => downloadHandler('XLSX', false, true)}
            />
          </>
        )}
      </div>
      <header className={cn(style.header, style.second)}>
        <h3>Export cen dostawcy</h3>
        <p>
          Możesz też pobrać szablon wypełniony cenami jednego z dostawców, wybierając go z poniższej listy. Ten plik również można
          zaimportować.
        </p>
      </header>
      <AsyncSelect
        value={selectedSupplier}
        onChange={setSelectedSupplier}
        apiCallback={UserApi.getDistributors}
        valueKey={'id'}
        labelKey={'name'}
        label={'Dostawca'}
        placeholder={'Dostawca'}
        queryParams={{ pagination: 1, ...(isAdmin && { company_id: selectedCompany }) }}
        validator={validator}
        rule={'required'}
        isClearable
      />
      <div className={style.buttonsWrapper}>
        <Button
          label={'Pobierz ceny (.csv)'}
          isLoading={isLoadingCSVSupplier}
          onClick={() => downloadHandler('CSV', true)}
        />
        <Button
          label={'Pobierz ceny (.xlsx)'}
          isLoading={isLoadingXLSXSupplier}
          onClick={() => downloadHandler('XLSX', true)}
        />
      </div>
      <header className={cn(style.header, style.second)}>
        <h3>Export całego katalogu</h3>
        <p>
          Pobierz plik .excel z zawartością całego katalogu. Dostawcy zaznaczeni na <span className={style.green}>zielono</span>{' '}
          są wybrani jako pryiorytetowy, zaznaczeni na <span className={style.blue}>niebiesko</span> jako alternatywni, a
          zaznaczeni na <span className={style.yellow}>żółto</span> - jako zapasowi.
        </p>
      </header>
      <Button
        label={'Pobierz ceny wszystkich dostawców'}
        isLoading={isLoadingWholeCatalog}
        onClick={downloadWholeCatalog}
      />
    </div>
  );
};

export default FlatFileExport;
