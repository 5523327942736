import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Button } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ListsApi } from 'src/api';
import { OfferCatalogApi } from 'src/api';
import ProductsList from 'src/features/CatalogAndCartController/components/ProductsList';

import { refreshCartOrCatalog } from '../../actions';
import ProductsComparasion from './components/ProductsComparasion';

import styles from './ProductReplacementsForm.module.scss';

const ProductReplacementsForm = ({ productsWithOffersIds, currentProduct, closeModal, isCatalogView }) => {
  const dispatch = useDispatch();
  const { listUID } = useParams();

  const [selectedReplacement, setSelectedReplacement] = useState(null);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleReplaceProducts = async (e) => {
    e.preventDefault();

    const params = {
      list_uid: listUID,
      from_product_id: currentProduct.product_id,
      to_product_id: selectedReplacement.product_id
    };

    try {
      setIsSubmitting(true);
      const { data } = await ListsApi.replaceProducts(params);

      dispatch(refreshCartOrCatalog());
      closeModal();
      notifyCommon([data.message]);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const { data } = await OfferCatalogApi.getData({
          page: 1,
          perPage: 50,
          product_ids: productsWithOffersIds,
          product_cart_quantity: currentProduct?.quantity || undefined
        });

        setProducts(data.data.products);
      } catch (err) {
        notifyApiError(err);
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, []);

  return (
    <form
      className={styles.container}
      onSubmit={handleReplaceProducts}
    >
      <ProductsComparasion
        currentProduct={currentProduct}
        selectedReplacement={selectedReplacement}
      />
      {isLoading ? (
        <Skeleton
          width={'100%'}
          height={95}
          count={productsWithOffersIds.length}
        />
      ) : (
        <>
          <ProductsList
            isCatalogView={isCatalogView}
            products={products}
            //   selectedCompany={selectedCompany}
            replacementsList
            setReplacement={setSelectedReplacement}
            currentReplacement={selectedReplacement}
            customQuantity={currentProduct?.quantity}
          />
          <Button
            label={!selectedReplacement ? 'Wybierz zamiennik' : 'Zatwierdź wybór zamiennika'}
            isLoading={isSubmitting}
            className={styles.button}
            disabled={!selectedReplacement}
          />
        </>
      )}
    </form>
  );
};

export default ProductReplacementsForm;
